//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
      twoFaPin: '',
      showPassword: false,
      processing: false,
    };
  },

  computed: {
    isSignInPossible() {
      const { email, password, twoFaPin } = this;
      return email && password && twoFaPin;
    },
  },

  watch: {
    twoFaPin(value) {
      const { email, password } = this;
      if (
        value.length === 6
        && email
        && password
      ) {
        this.signInClick();
      }
    },
  },

  created() {
    this.$vuetify.theme.dark = false;
  },

  methods: {
    ...mapActions('login', ['signIn']),
    ...mapActions('common', ['setErrorNotification']),

    async signInClick() {
      this.processing = true;
      try {
        const { email, password, twoFaPin } = this;
        await this.signIn({ email, password, twoFaPin });
      } catch (error) {
        const { message, messageCode } = error;

        if (messageCode === 127) { // Your 2FA pin is not valid
          this.twoFaPin = '';
        }

        this.setErrorNotification(message);
      }
      this.processing = false;
    },
  },
};
